$url: '../fonts/'; 	

@font-face {
  font-family: 'fontello';
  src: url('#{$url}fontello.eot?36485668');
  src: url('#{$url}fontello.eot?36485668#iefix') format('embedded-opentype'),
       url('#{$url}fontello.woff2?36485668') format('woff2'),
       url('#{$url}fontello.woff?36485668') format('woff'),
       url('#{$url}fontello.ttf?36485668') format('truetype'),
       url('#{$url}fontello.svg?36485668#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face{
    font-family: 'ProximaNova';
    font-weight: 200;
    font-style: normal;

    src: url('#{$url}ProximaNova-Light.eot?#iefix') format('embedded-opentype'),
    url('#{$url}ProximaNova-Light.woff') format('woff'),
    url('#{$url}ProximaNova-Light.ttf') format('truetype'),
    url('#{$url}ProximaNova-Light.otf') format('otf');
}

@font-face{
    font-family: 'ProximaNova';
    font-weight: normal;
    font-style: normal;

    src: url('#{$url}ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
    url('#{$url}ProximaNova-Regular.woff') format('woff'),
    url('#{$url}ProximaNova-Regular.ttf') format('truetype'),
    url('#{$url}ProximaNova-Regular.otf') format('otf');
}

@font-face{
    font-family: 'ProximaNova';
    font-weight: 500;
    font-style: normal;

    src: url('#{$url}ProximaNova-Semibold.eot?#iefix') format('embedded-opentype'),
    url('#{$url}ProximaNova-Semibold.woff') format('woff'),
    url('#{$url}ProximaNova-Semibold.ttf') format('truetype'),
    url('#{$url}ProximaNova-Semibold.otf') format('otf');
}

@font-face{
    font-family: 'ProximaNova';
    font-weight: bold;
    font-style: normal;

    src: url('#{$url}ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
    url('#{$url}ProximaNova-Bold.woff') format('woff'),
    url('#{$url}ProximaNova-Bold.ttf') format('truetype'),
    url('#{$url}ProximaNova-Bold.otf') format('otf');
}