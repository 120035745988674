@import '../../../assets/styles/vars.scss';

.default {
	background-color: #fff;
	box-shadow: 0 0 2rem 0 #eee;
	padding: 1.2rem 2rem;
	text-align: left;
	margin: 0 auto 1rem;

	h3 {
	  position: relative;
	  font-size: 1.4rem;
	  font-weight: 600;
	  margin-bottom: .6rem;

	  &::first-letter {
	  	text-transform: uppercase;
	  }
	}
}

.list {
	margin-left: 1.4rem;

	li {
		position: relative;
		display: block;
		line-height: 1.4;
		margin-bottom: .7rem;
		padding-left: 2.3rem;
		vertical-align: middle;
		font-size: 1.3rem;
		
		&::before {
		  position: absolute;
		  left: 0;
		  width: 1.4rem;
      height: 1.4rem;
		  border-radius: 50%;
		  border: 2px solid;
		  line-height: 1.1;
		  font-family: sans-serif;
		  font-size: 1.3rem;
		  text-align: center;
		  vertical-align: middle;
		}
	}
}

.pros {
	@extend .default;

	h3 {
		color: $theme-color-2;
	}

	li {
		&::before {
		  content: '+';
		  color: $theme-color-2;
		}
	}
}

.cons {
	@extend .default;

	h3 {
		color: $theme-color-1;
	}

	li {
		&::before {
		  content: '-';
		  color: $theme-color-1;
		}
	}
}