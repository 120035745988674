@import 'vars';

html {
	font-family: $font-main;
	font-size: $fsz-base;
	color: $font-color;
	overflow-y: scroll;

	@media(min-width: 1920px) {
		font-size: $fsz-max;
	}

	@media(max-width: 1280px) {
		font-size: $fsz-min;
	}	

	@media(max-width: 1024px) {
		background-size: cover;
		background-position: center;
	}
}

body {
	background-color: $body-bg;
}

p, h2, h3 {
	line-height: 1.4;
}

b {
	font-weight: bold;
}

* {
	-moz-user-select: none;
	-html-user-select: none;
	user-select: none; 
	unselectable: on;
}

label {
	display: block;
}

input {
	font-family: $font-main;
	background-color: transparent;
	border: none;

	-webkit-user-select: text;
	-html-user-select: text;
	-moz-user-select: text;
	user-select: text;
}

#root {
	overflow-x: hidden;
}

%clearfix {
	&::after {
		content: '';
		display: block;
		clear: both;
	}
}

button:active, button:focus {
  outline: none;
}
button::-moz-focus-inner {
  border: 0;
}