@mixin spin($animation, $i, $time, $reverse: normal) {
	@at-root {
		$cycle: 120+$i*40;
		@keyframes #{$animation} {
		  0% {
		    stroke-dasharray: 0 $cycle;
		    transform: rotate(0);
		  }
		  50% {
		    stroke-dasharray: $cycle;
		    transform: rotate(180deg);
		  }
		  100% {
		    stroke-dasharray: 0 $cycle;
		    transform: rotate(720deg);
		  }
		}
	}

	transform-origin: center;
	animation: $animation $time ease infinite forwards $reverse;
}

.preloader {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
  margin: 0;
  height: 100vh;
  transition: background-color 0.3s ease;
  background-color: #f3f3f3;
}

.indicator {
	position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
	margin: auto;
	z-index: 10;
	width: 80px;
	height: 80px;
	opacity: 1;
	transition: .3s ease;
}

.svg {
	width: 100%;
	height: 100%;
}
 	
.diagram {
	fill: transparent;
	stroke-width: 4px;
	stroke-dasharray: 0 240;

	&:nth-child(1) {
		@include spin(outerCircle, 3, 2.2s);
		stroke: #eee;
	}

	&:nth-child(2) {
		@include spin(middleCircle, 2, 2.4s, reverse);
		stroke: #ddd;
	}

	&:nth-child(3) {
		@include spin(innerCircle, 1, 2.6s);
		stroke: #ccc;
	}
}

.default {
	@extend .preloader;
}

.transparent {
	@extend .preloader;
	background-color: rgba(#f3f3f3, .7);
}