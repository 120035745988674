.default {
	font-size: 1.3rem;
	padding: .3rem 0;
	
	&::first-letter {
		text-transform: uppercase;
	}

  @media(max-width: 600px) {
    font-size: 1.1rem;
  }
}

.noWrap {
  white-space: nowrap;
}