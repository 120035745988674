.header {
	font-size: 1.3em;
	margin-bottom: 1.5rem;
}

.advantages {
	font-size: 1.3em;
	max-width: 53rem;
	line-height: 1.3;
	margin: 0 auto 2rem;
	text-align: left;
	background-color: #fff;
	padding: 1.2rem 2rem;
	box-shadow: 0 0 2rem 0 #eee;

	li {
		margin-bottom: .7rem;
	}
}