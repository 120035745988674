@import "../../assets/styles/vars.scss";

.results {
	max-width: 53rem;
	margin: 0 auto;
  padding: 2rem 0;
	text-align: left;

  @media(max-width: 800px) {
    padding: 1rem;
  }
}

.chapter {
	position: relative;
	width: 100%;
	min-height: 10rem;
	font-size: 3em;
	font-weight: 700;
	margin: 0 0 1.8rem;
	line-height: 1.2;
	color: $mark;

	i {
		display: block;
	}

	&::after {
	  content: '';
	  position: absolute;
	  top: .4rem;
	  left: -33rem;
	  margin: auto 0;
	  height: 10rem;
	  width: 30rem;
	  background-color: $mark;
	}

	@media(max-width: 600px) {
	  font-size: 2.2em;
    min-height: auto;

    i {
      display: inline;
    }

	  &:after {
	    display: none;
	  }
	}
}

.block {
	margin-bottom: 3.5rem;

	&>p, &>ul {
	  font-size: 1.3rem;
	  margin: 0 auto 1.2rem;

    @media(max-width: 600px) {
      font-size: 1.1rem;
      line-height: 1.5;
    }
	}
}

.heading {
  position: relative;
  font-size: 2.2em;
  font-weight: 700;
  margin-bottom: 3.5rem;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: .4rem;
    left: 0;
    bottom: -1.4rem;
    background-color: $mark;
  }

  @media(max-width: 600px) {
    font-size: 1.7em;
    line-height: 1.1;
    margin-bottom: 2.2rem;

    &::after {
      bottom: -1.1rem;
    }
  }
}

.braceImg {
	width: 100%;
  overflow: hidden;
  max-height: 26rem;
  margin-bottom: 1.5rem;

  img {
  	width: 100%;
    height: auto;
  }
}

.details {
	margin-top: 1rem;
	&::first-letter {
		text-transform: uppercase;
	}

	li {
		line-height: 1;
	}
}

.list {
	li {
		position: relative;
		padding-left: 1.5rem;
		margin-bottom: 1.3rem;
		vertical-align: middle;

		&::before {
			content: '+';
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			margin: auto 0;
			font-size: 1.4em;
			font-weight: bold;
			line-height: 1.4rem;
			vertical-align: middle;
			color: $mark;
		}
	}
}