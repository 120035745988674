$font-main: ProximaNova, sans-serif;
$font-second: 'Open Sans', sans-serif;

$fsz-base: 1.15vw;
$fsz-min: 15px;
$fsz-max: 22px;

$color-white: #fffefc;
$color-whitesmoke: #fafafa;
$color-gray: #f3f3f3;
$color-rose: #e2252a;
$color-red: #ff4136;
$color-malachite: #00B399;
$color-ebony: #080401;
$color-mango: #ffeabe;
$color-caramel: #f9a85c;

$theme-color-1: $color-rose;
$theme-color-2: $color-malachite;
$font-color: $color-ebony;
$body-bg: $color-whitesmoke;

/*components*/

/*button*/
$button-bg: $theme-color-1;
$button-bg-onhover: $color-red;
$button-bg-onactive: rgba($color-red, .4);
$button-color: $color-white;
$button-font: $font-main;

/*progress*/ 
$progress-bg: $color-gray;
$progress-track-bg: $theme-color-2;

/*checkbox*/ 
$checkbox-checked: $theme-color-2;

/*answer*/ 
$answer-checked: $theme-color-2;

$error-font: $color-caramel;
$error-bg: $color-mango;

/*results*/
$mark: teal;	