$animations: (slide, fade, flip, zoom);
$states: (in, out);
$sides: (left, right, up, down, static);

@each $animation in $animations {
  @each $side in $sides {
    @each $state in $states {
      .#{$animation}-#{$state}-#{$side} {
        animation-name: #{$animation}-#{$state}-#{$side};
      }
    }
  }
}

@keyframes slide-in-up {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes slide-out-up {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-100%);
  }
}

@keyframes slide-in-left {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slide-out-left {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

@keyframes slide-in-right {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slide-out-right {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(100%);
  }
}

@keyframes fade-in-left {
  from {
    transform: translateX(-80%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-out-left {
  from {
  	transform: translateX(0);
    opacity: 1;
  }

  to {
    transform: translateX(-80%);
    opacity: 0;
  }
}

@keyframes fade-in-right {
  from {
    transform: translateX(80%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-out-right {
  from {
  	transform: translateX(0);
    opacity: 1;
  }

  to {
    transform: translateX(80%);
    opacity: 0;
  }
}

@keyframes fade-in-up {
  from {
    transform: translateY(-80%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-out-up {
  from {
    transform: translateY(0);
    opacity: 1;
  }

  to {
    transform: translateY(-80%);
    opacity: 0;
  }
}

@keyframes fade-in-static {
	from { opacity: 0 }
	to 	 { opacity: 1 }
}

@keyframes fade-out-static {
	from { opacity: 1 }
	to 	 { opacity: 0 }
}

@keyframes flip-in-left {
	0% {
		transform: perspective(400px) rotateY(90deg);
		animation-timing-function: ease-in;
		opacity: 0;
	}
	40% {
		transform: perspective(400px) rotateY(-20deg);
    animation-timing-function: ease-in;
	}
	60% {
		transform: perspective(400px) rotateY(10deg);
		opacity: 1;
	}
	80% {
		transform: perspective(400px) rotateY(-5deg);
	}
	100% {
		transform: perspective(400px);
	}
}

@keyframes flip-out-left {
	from {
		transform: perspective(400px);
	}
	30% {
		transform: perspective(400px) rotateY(-15deg);
		opacity: 1;
	}
	to {
		transform: perspective(400px) rotateY(90deg);
		opacity: 0;
	}
}

@keyframes flip-in-up {
	0% {
		transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
		animation-timing-function: ease-in;
		opacity: 0;
	}
	40% {
		transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in;
	}
	60% {
		transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
		opacity: 1;
	}
	80% {
		transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
	}
	100% {
		transform: perspective(400px);
	}
}

@keyframes flip-out-up {
	from {
		transform: perspective(400px);
	}
	30% {
		transform: perspective(400px) rotateX(-15deg);
		opacity: 1;
	}
	to {
		transform: perspective(400px) rotateX(90deg);
		opacity: 0;
	}
}

@keyframes flip-in-down {
	0% {
		transform: perspective(400px) rotate3d(-1, 0, 0, 90deg);
		animation-timing-function: ease-in;
		opacity: 0;
	}
	40% {
		transform: perspective(400px) rotate3d(-1, 0, 0, -20deg);
    animation-timing-function: ease-in;
	}
	60% {
		transform: perspective(400px) rotate3d(-1, 0, 0, 10deg);
		opacity: 1;
	}
	80% {
		transform: perspective(400px) rotate3d(-1, 0, 0, -5deg);
	}
	100% {
		transform: perspective(400px);
	}
}

@keyframes flip-out-down {
	from {
		transform: perspective(400px);
	}
	30% {
		transform: perspective(400px) rotate3d(-1, 0, 0, -15deg);
		opacity: 1;
	}
	to {
		transform: perspective(400px) rotate3d(-1, 0, 0, 90deg);
		opacity: 0;
	}
}

@keyframes zoom-in-static {
	from {
		transform: scale(0);
	}

	to {
		transform: scale(1);
	}
}

@keyframes zoom-out-static {
	from {
		transform: scale(1);
		opacity: 1;
	}

	to {
		transform: scale(0);
		opacity: 0;
	}
}