@import '../../../assets/styles/vars.scss';

.default {
	background-color: #fff;
	box-shadow: 0 0 2rem 0 #eee;
	padding: 1.2rem 2rem;
	text-align: left;
	margin: 0 auto 1rem;

	h3 {
	  position: relative;
	  font-size: 1.4rem;
	  font-weight: 600;
	  margin-bottom: .6rem;

	  &::first-letter {
	  	text-transform: uppercase;
	  }
	}

	b {
		font-weight: medium;
	}

	&>p {
		font-size: 1.3rem;
		margin-bottom: 0;
	}

	mark {
		font-weight: medium;
		color: $theme-color-2;
		background-color: transparent;
	}
}